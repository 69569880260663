/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import cx from 'classnames';
import styles from '../../styles/pages/landing.module.scss';

const data = {
  topTitle: `ペイディが選ばれる理由`,
  image1: {
    file: {
      url: require('../../images/landing/paidy-card-1.png'),
    },
    title: `楽しみ方が広がるペイディカード`,
  },
  title1: (
    <>
      楽しみ方が広がる
      <br />
      ペイディカード
    </>
  ),
  text1: `Visaが使えるオンラインショップやペイディアプリで、ペイディを利用してお買い物を楽しめます。`,
  image2: {
    file: {
      url: require('../../images/6pay/multipay.png'),
    },
    title: `３・６回あと払いが分割手数料無料*`,
  },
  title2: (
    <>
      ３・６回あと払いが
      <br />
      分割手数料無料*
    </>
  ),
  text2: `分割手数料無料*で、お支払いを３・６回に分けることができます。お支払い総額はそのままで月々のお支払いを調整できるので、よりかしこく計画的にお買い物を楽しめます。`,
  image3: {
    file: {
      url: require('../../images/6pay/balance-limit.png'),
    },
    title: `予算設定で
    使い過ぎを防止`,
  },
  title3Pc: (
    <>
      予算設定で
      <br />
      使い過ぎを防止
    </>
  ),
  title3Sp: `使い過ぎを防止`,
  text3: `予算設定機能や、いつでもすぐに確認できる利用履歴、利用通知メールなど、便利な機能で使い過ぎを防止。`,
  text4: (
    <>
      <span className={styles.plusList_textSmall}>
        *口座振替・銀行振込のみ無料
        <br />
        ※３回あと払いは1回のお買い物につき、3,000円以上のお支払いで利用可能です。6回あと払いは1回のお買い物につき6,000円以上のお支払いで利用可能です。
      </span>
    </>
  ),
};

const Plus = () => {
  return (
    <section className={cx(styles.section, styles.plus)}>
      <div className={styles.inner}>
        <h2 className={styles.plusTopTitle}>{data.topTitle}</h2>
        <ul className={styles.plusList}>
          <li>
            <div className={styles.plusList_img}>
              <img src={data.image1.file.url} alt={data.image1.title} />
            </div>
            <h3 className={styles.plusList_ttl}>{data.title1}</h3>
            <p className={styles.plusList_text}>{data.text1}</p>
          </li>
          <li>
            <div className={styles.plusList_img}>
              <img src={data.image2.file.url} alt={data.image2.title} />
            </div>
            <h3 className={styles.plusList_ttl}>{data.title2}</h3>
            <p className={styles.plusList_text}>{data.text2}</p>
            <p className={styles.plusList_textSmallSp}>{data.text4}</p>
          </li>
          <li>
            <div className={styles.plusList_img}>
              <img src={data.image3.file.url} alt={data.image3.title} />
            </div>
            <h3 className={cx(styles.plusList_ttl, styles.pc)}>
              {data.title3Pc}
            </h3>
            <h3 className={cx(styles.plusList_ttl, styles.sp)}>
              {data.title3Sp}
            </h3>
            <p className={styles.plusList_text}>{data.text3}</p>
          </li>
        </ul>
        <p className={styles.plusList_textSmallPc}>{data.text4}</p>
      </div>
    </section>
  );
};

export default Plus;
