/* eslint-disable react/no-danger */
// @flow
import React from 'react';
import cx from 'classnames';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import useAppURLsByDevice from 'src/hooks/useAppUrlsByDevice';
import QrCodeImg from 'src/images/6pay/qr-code-full.png';
import PaidyLogo from 'src/images/landing/paidy_logo.svg';
import styles from './ConverionBottom6Pay.module.scss';
import { trackAppDownload } from '../../utils';

const data = {
  logo: {
    file: {
      url: QrCodeImg,
      title: 'qr',
    },
  },
  textPc: `本人確認は<br />
  ペイディアプリから。`,
  textSp: `本人確認は<br />
  ペイディアプリから。`,
  appStore: {
    file: {
      url: require('../../images/badge-app-store.svg'),
    },
  },
  googlePlay: {
    file: {
      url: require('../../images/badge-google-play.svg'),
    },
  },
};

const sp = {
  appStoreUrl: 'https://d9r4z.app.goo.gl/6pay',
  playStoreUrl: 'https://d9r4z.app.goo.gl/6pay',
};

const pc = {
  appStoreUrl: 'https://apps.apple.com/JP/app/id1220373112?mt=8',
  playStoreUrl:
    'https://play.google.com/store/apps/details?id=com.paidy.paidy&referrer=af_tranid%3DJK6y7wDsEZLNEvXx9Zt3pQ%26shortlink%3DPC%26c%3DPC%26pid%3Dwebsite%26af_channel%3Dbutton%26source_caller%3Dui%26af_web_id%3D881895a9-99cf-4d63-8407-23ccc2fa2d1a-o',
};

const ConversionBottom = () => {
  const { appStoreUrl, playStoreUrl } = useAppURLsByDevice(sp, pc);

  return (
    <div className={styles.conversionTop}>
      <div className={styles.inner}>
        <OutboundLink
          className={styles.conversionTop_logo}
          href="https://d9r4z.app.goo.gl/6pay"
          target="_blank"
          rel="noopener noreferrer"
          loading="lazy"
        >
          <img
            src={data.logo.file.url}
            alt={data.logo.file.title}
            width={130}
            height={130}
          />
        </OutboundLink>
        <div className={styles.conversionTop_right}>
          <div className={styles.conversionTop_txtWrapper}>
            <img src={PaidyLogo} alt={'Paidy logo'} width={50} height={50} />
            <p
              className={cx(styles.conversionTop_text, styles.pc)}
              dangerouslySetInnerHTML={{ __html: data.textPc }}
            />
            <p
              className={cx(styles.conversionTop_text, styles.sp)}
              dangerouslySetInnerHTML={{ __html: data.textSp }}
            />
          </div>
          <div className={styles.conversionTop_btnWrapper}>
            <a
              className={styles.conversionTop_btn}
              href={appStoreUrl}
              rel="noreferrer"
              target="_blank"
              onClick={() =>
                trackAppDownload('App Store', 'landing_bottom_6pay')
              }
            >
              <img
                src={data.appStore.file.url}
                alt="App Storeからダウンロード"
              />
            </a>
            <a
              className={styles.conversionTop_btn}
              href={playStoreUrl}
              rel="noreferrer"
              target="_blank"
              onClick={() =>
                trackAppDownload('Google Play', 'landing_bottom_6pay')
              }
            >
              <img
                src={data.googlePlay.file.url}
                alt="Google Playで手に入れよう"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConversionBottom;
